<template>
  <div class="header-logo-wrapper col-auto p-0">
    <div class="logo-wrapper">
      <router-link to="/">
        <img class="img-fluid " src="../../assets/images/logo/logo-simple-red-white.png" alt
          v-if="this.$route.path !== '/player' && this.$route.path !== '/karaoke'" />
        <img class="img-fluid " src="../../assets/images/logo/logo-simple-red-white.png" alt
          v-if="this.$route.path === '/player' || this.$route.path === '/karaoke'" />
      </router-link>
    </div>
    <!-- <div class="toggle-sidebar" @click="toggle_sidebar">
      <vue-feather class="status_toggle middle sidebar-toggle" type="align-center" id="sidebar-toggle"></vue-feather>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Logo',
  methods: {
    toggle_sidebar() {
      this.$store.dispatch('menu/opensidebar');
    },
  },
};
</script>

<style scoped>
.logo-wrapper {
  display: block !important;
}

.logo-wrapper img {
  width: auto;
  height: 35px;
}

.header-logo-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

@media(max-width: 991px) {
  .logo-wrapper {
    display: block !important;
  }

  .logo-wrapper img {
    width: auto;
    height: 25px;
  }
}
</style>
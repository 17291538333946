<template>
  <div class="container-fluid">

    <div class="row starter-main banners-carousel left-spaced  p-r-10" v-if="!this.selectedTag">
      <div class="col-md-12 p-0">
        <Carousel :autoplay="5000" :wrapAround="true" :items-to-show="1" ref="refBannerCarousel">
          <Slide v-for="(banner, index) in songlist.banners" :key="'banner-' + index">
            <div class="carousel__item" :style="{ 'background-image': 'url(' + banner[3] + ')' }">

              <h4 v-if="banner[0]">
                {{ banner[0] }}
              </h4>

              <h2 v-if="banner[2]">
                {{ banner[2] }}
              </h2>

              <div v-if="banner[1]" class="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                <button class="btn py-3 bt-banner-add" type="button" style=""
                  @click="this.$store.dispatch('addToPlaylist', { song: banner, messages: true });"><i
                    class="fa fa-plus"></i>
                  &nbsp; Adicionar</button>
                <button class="btn py-3 bt-banner-play" type="button" style="" @click="() => {
                  this.$store.dispatch('addToPlaylist', { song: banner, position: 0, messages: true });
                  this.$store.dispatch('setCurrentSong', { song: banner });
                  this.$router.push({ name: 'player' })
                }
                  "><i class="fa fa-play"></i>
                  &nbsp; Cantar</button>
              </div>

            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>




    <!-- <div class="row starter-main px-0 left-spaced">
      <div class="col-md-12 p-0">

        <div class="m-t-50 m-b-50"
          style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
          <h5 class="f-w-700 m-r-30 my-0">Navegue por Tags</h5>

          <span style="cursor: pointer;" class="badge rounded-pill badges-tags px-4 py-3 f-16 f-w-400 mx-2"
            :class="{ 'tag-selected': this.selectedTag === tag }"
            @click="this.selectedTag = this.selectedTag === tag ? null : tag" v-for="(tag, index) in this.tags"
            :key="'tag-' + index">
            {{ tag }}<span v-if="this.selectedTag === tag"> &nbsp; <i class="icon-close"></i></span>
          </span>

        </div>

      </div>
    </div> -->

    <div class="row starter-main px-0 left-spaced">
      <div class="col-md-12 p-0">

        <div class="m-t-50 m-b-50"
          style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
          <h5 class="f-w-700 m-r-30 my-0">Navegue por Tags</h5>

          <div class="hidden-scroll"
            style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; overflow-x: scroll;">
            <router-link :to="getTagSearchUrl(tag)" class="badge rounded-pill badges-tags px-4 py-3 f-16 f-w-400 mx-2"
              v-for="(tag, index) in this.tags" :key="'tag-' + index">
              {{ tag }}
            </router-link>
          </div>






        </div>

      </div>
    </div>







    <div class="row starter-main left-spaced p-r-20" v-if="!this.selectedTag">
      <div class="col-md-12 p-0">
        <h3 class="f-w-700 mb-0">Novidades</h3>
      </div>
    </div>

    <div class="row starter-main songs-cards-carousel " v-if="!this.selectedTag">
      <div class="col-md-12 p-0">
        <Carousel v-bind="carouselSettings" :breakpoints="breakpoints" class="carousel-row">
          <Slide v-for="(song, index) in songlist.news" :key="'new-' + song[1]">
            <Songcard :song="song" />
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>



    <!-- <div v-if="!this.selectedTag" class="row starter-main left-spaced p-r-20">
      <div class="col-md-12 p-0">

        <h3 class="my-3 f-w-700">Novidades</h3>

        <div
          style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;  flex-wrap: wrap;">
          <Songcard v-for="(song, index) in songlist.news" :key="'new-' + song[1]" :song="song" />

        </div>

      </div>
    </div> -->




    <div v-if="!this.selectedTag" class="row starter-main left-spaced p-r-20" v-for="selectionTag in this.selectionTags"
      :key="'selectiontag-' + selectionTag">
      <div class="col-md-12 p-2">

        <h3 class="my-3 f-w-700">Destaques - {{ selectionTag }}</h3>

        <div style="position: relative; display: flex;  justify-content:space-between; padding: 0; flex-wrap: wrap;">

          <Songcard v-for="(song, index) in songlist.selections.filter(s => s[3] === selectionTag)" :song="song"
            :key="'selection-' + song[1]" />

        </div>

      </div>
    </div>










  </div>
  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Footer from '../components/footer.vue';
import Songcard from '../components/songcard.vue';




export default {

  components: {
    Footer,
    Songcard,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      selectionTags: [],
      tags: [],
      selectedTag: null,
      songlist: [],
      teste: null,
      loaded: false,
      // song cards carousel settings
      carouselSettings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 6.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {




  },
  computed: {
    songlist: function () {
      var songlist = this.$store.state.songlist;
      this.tags = this.loadTags(songlist.songs || []);
      this.selectionTags = this.loadTags(songlist.selections || []);
      return songlist;
    },
  },
  methods: {
    loadTags(list) {
      var tags = [];
      list.forEach(item => {
        if (item[3]) {
          var str = item[3];
          var splitted = str.split(",");
          splitted.forEach(s => {
            tags.push(s.trim())
          })
        }
      });
      tags = this.sortTags(tags);
      tags = [...new Set(tags)];
      //console.log(tags, 'tags');
      return tags;
    },
    sortTags(array) {
      var occ = {};
      // Contar o número de ocorrências de cada valor
      array.forEach(function (val) {
        occ[val] = (occ[val] || 0) + 1;
      });
      // Ordenar a array usando a função de comparação
      array.sort(function (a, b) {
        return occ[b] - occ[a];
      });
      return array;
    },

    getTagSearchUrl(tag) {

      return '/pesquisa/tag:' + tag.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    }

    /* isSameText(text1, text2) {

      console.log('isSameText?', text1, text2);
      //text1 = text1.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
      //text2 = text2.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
      return text1 === text2;
    } */


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style>
.left-spaced {
  margin-left: 100px !important;
}

.left-padded {
  padding-left: 100px !important;
}

.banners-carousel .carousel {
  border-radius: 8px !important;
  overflow: hidden !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, .75) !important;
}

.banners-carousel .carousel__item {
  min-height: 80vh;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  padding: 100px;
}

.carousel__slide--active .carousel__item {
  animation: expandBackground 5s infinite alternate;
}

@keyframes expandBackground {
  0% {
    background-size: 100%;
    /* Começa com o tamanho original */
    background-position: center;
  }

  100% {
    background-size: 120%;
    /* Zoom máximo */
    background-position: center;
    /* Movimento adicional */
  }
}



.banners-carousel .carousel__item h2 {
  font-size: 100px;
  font-weight: 900;
  color: #fff !important;
  text-shadow: 3px 3px 1px #000;
}

.banners-carousel .carousel__item h4 {
  font-size: 30px;
  font-weight: 500;
  color: #fff !important;
  text-shadow: 2px 2px 1px #000;
}




.carousel__slide {
  padding: 0;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: none;
  margin: 0 10px;
  background-color: rgba(0, 0, 0, .5);
  color: #eee !important;
  padding: 25px 5px !important;
}

.carousel__prev:hover,
.carousel__next:hover {
  color: #fff;
}



.carousel__next--disabled,
.carousel__prev--disabled {
  opacity: 0 !important;
}


.songs-cards-carousel .carousel__prev,
.songs-cards-carousel .carousel__next {
  display: none !important;
}



.songs-cards-carousel .carousel__track {
  padding-left: 40px !important;
}

.songs-cards-carousel .carousel__slide .songcard-wrapper {
  width: 250px !important;
}



.carousel-row .carousel__track {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 60px;
}




.page-wrapper.advance-layout .page-header {
  background-color: none !important;
}

.bt-banner-add {
  background-color: rgb(235, 235, 235) !important;
  border-color: rgb(235, 235, 235) !important;
  color: #333 !important;
  font-weight: 600 !important;
}

.bt-banner-play {
  background-color: rgba(50, 150, 100, 1) !important;
  border-color: rgba(50, 150, 100, 1) !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.bt-banner-add:hover {
  background-color: rgb(225, 225, 255) !important;
}

.bt-banner-play:hover {
  background-color: rgba(40, 140, 90, 1) !important;
}


.badges-tags {

  background-color: #ddd !important;
  color: #333 !important;
}

.badges-tags.tag-selected {
  background-color: #333 !important;
  color: #ddd !important;
}


.hidden-scroll {
  overflow-x: scroll;
  /* Ativa o deslocamento horizontal */
  -ms-overflow-style: none;
  /* Esconde a barra no Internet Explorer e no Edge */
  scrollbar-width: none;
  /* Esconde a barra no Firefox */
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
  /* Esconde a barra no Chrome, Safari e Edge Chromium */
}



@media(max-width: 991px) {
  .left-spaced {
    margin-left: 0 !important;
  }

  .left-padded {
    padding-left: 0 !important;
  }

  .songs-cards-carousel .carousel__track {
    padding-left: 20px !important;
  }

  .songs-cards-carousel .carousel__slide {
    width: 66vw !important;
    height: auto !important;
    margin: 0 10px !important;
  }

  .carousel-row .carousel__track {
    margin-left: 0px !important;
    ;
  }

  .carousel__prev,
  .carousel__next {
    display: none !important;
  }

  .banners-carousel .carousel__item {
    min-height: 33vh;
    background-size: cover;
    padding: 10px;
  }

  .banners-carousel .carousel__item {
    padding: 10px;
  }

  .banners-carousel .carousel__item h2 {
    font-size: 25px;
  }

  .banners-carousel .carousel__item h4 {
    font-size: 15px;
  }


  @keyframes expandBackground {
    0% {
      background-size: 150%;
      /* Começa com o tamanho original */
      background-position: center;
    }

    100% {
      background-size: 160%;
      /* Zoom máximo */
      background-position: center;
      /* Movimento adicional */
    }
  }




}
</style>
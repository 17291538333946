<template>
  <div class="container-fluid">
    <div class="row starter-main">
      <div class="col-lg-4 col-sm-12 keyboard-wrapper" style="background-color: transparent;">
        <Keyboard @key-click="handleKeyClick" />
      </div>
      <div class="col-lg-8 col-sm-12 search-wrapper" style="background-color: transparent;">
        <input type="text" v-model="terms" placeholder="Pesquisar por..." autocomplete="off" name="q" title=""
          ref="searchinput" id="searchinput" @focus="this.$store.dispatch('setShortcuts', false)"
          @blur="this.$store.dispatch('setShortcuts', true)" @input="debounceInput" @click="debounceInput"
          class="search-input">




        <div class="search-results">
          <div v-if="this.searchResults.length > 0">

            <p class="my-2 py-2 text-center txt-results">Sua pesquisa retornou {{ this.searchResults.length }}
              resultados.</p>


            <div class="my-0 py-0" id="results-wrapper">

              <RecycleScroller class="scroller" :items="searchResults" :item-size="cardHeight" page-mode :prerender="20"
                :item-secondary-size="cardWidth" :grid-items="gridItems" key-field="1" :buffer="500" @resize="onResize">
                <template v-slot="{ item }">
                  <div style="padding: 0px;">
                    <Songcard :song="item" />
                  </div>
                </template>
              </RecycleScroller>

            </div>





          </div>
          <div v-if="this.terms && this.searchResults.length === 0 && this.loaded">
            <div class="tt-dataset tt-dataset-0">
              <div class="EmptyMessage txt-results">
                Oops! Sua pesquisa não retornou nenhum resultado.


              </div>
            </div>
          </div>

          <div v-if="this.terms && !this.loaded">
            <div class="tt-dataset tt-dataset-0">
              <div class="EmptyMessage txt-results">
                {{ this.terms.length > 2 ? "Pesquisando músicas, por favor aguarde..." : "Continue digitando para pesquisar..." }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Keyboard from './keyboard.vue';
import { mapState } from 'vuex';
import _ from 'lodash'
import { queryArtistPhoto } from '../data/songlist';
import Songcard from './songcard.vue';


export default {
  components: {
    Keyboard,
    Songcard
  },
  data() {
    return {
      gridItems: 5,
      cardHeight: 300,
      cardWidth: 220,
      terms: this.$route.params.terms ?? '',
      minTermsLenght: 3,
      searchResult: false,
      searchResultEmpty: false,
      searchResults: [],
      loaded: false
      //songlist: [],
    };
  },
  mounted() {

    if (this.terms && this.$store.state?.songlist?.songs && this.$store.state?.songlist?.songs.length > 0) {
      this.debounceInput();
    }

    this.$refs.searchinput.focus();
    this.$nextTick(() => this.$refs.searchinput.focus());
    setTimeout(() => {
      document.getElementById('searchinput').focus();
    }, 100);

    console.log('Search Mounted');
    
  },
  watch: {
    songlist(newValue, oldValue) {
      console.log('watch songlist >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', newValue, oldValue);
      if (newValue !== oldValue) {
        this.songlistLoaded()
      }
    }
  },
  computed: {
    /* songlist: function () {
      var songlist = this.$store.state.songlist;

      console.log(songlist, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> computed HOME >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');


      this.tags = this.loadTags(songlist.songs || []);
      this.selectionTags = this.loadTags(songlist.selections || []);
      return songlist;
    }, */

    ...mapState({
      searchOpen: (state) => state.searchOpen,
      songlist: (state) => state.songlist,
    }),
  },
  methods: {
    songlistLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> watch', this.songlist, this.terms);

      if (this.terms) {
        this.debounceInput();
      }
    },
    handleKeyClick(key) {
      switch (key) {
        case 'Backspace':
          this.terms = this.terms.slice(0, -1);
          break;
        case 'Clear':
          this.terms = '';
          break;
        default:
          this.terms += key;
      }
      this.debounceInput();

    },




    debounceInput: _.debounce(function (e) {


      if (this.terms.length > 2) {
        this.searchterm(this.terms)
        if (!this.searchResults.length) this.searchResultEmpty = true;
        else this.searchResultEmpty = false;
      }
      else {
        this.searchResults = [];
        this.searchResult = false;
        this.searchResultEmpty = false;
      }



    }, 500),

    searchterm() {


      this.loaded = false;
      /* var songs = [];
      if (isProxy(this.$store.state.songlist.songs)) {
        songs = toRaw(this.$store.state.songlist.songs)
      } */

      var results = [...this.$store.state.songlist.songs].filter(item => this.filterData(item, this.terms));
      this.searchResults = [...results];


      console.log(this.searchResults, 'this.searchResults');

      this.loaded = true;

    },
    filterData(item, query) {
      var fails = 0;

      if (query.includes("tag:")) {
        fails = 1;

        query = query.replace("tag:", "");

        let splitedTags = item[3].split(",");
        splitedTags.forEach(tag => {

          if (tag.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === query.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
            fails = 0;
          }

        });

      } else {
        let splitedQuery = query.split(" ");
        splitedQuery.forEach(word => {
          if (word !== "") {
            if (!item.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(word.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
              fails++;
            }
          }
        });
      }


      return fails === 0 ? true : false;
    },
    getArtistPhoto(artist) {
      return artist ? queryArtistPhoto(artist) : '';
    },
    setCurrentSong(song) {
      this.$store.dispatch('addToPlaylist', { song });
      this.$store.dispatch('setCurrentSong', { song });


      if (this.$router.currentRoute !== 'player' && this.$router.currentRoute !== 'karaoke') {
        this.$router.push({ name: 'player' })
      }

    },
    onResize() {
      // We use this to get the width of the scrolling container:

      const resultsWrapper = document.getElementById('results-wrapper');
      const w = resultsWrapper.offsetWidth;

      // Calculate the maximum media posters we can fit in a row based on the scroller width:
      this.gridItems = Math.floor(w / (this.cardWidth + 10));
    }
  },
  setup() {

    return {
      modules: [],
    };
  },
};
</script>

<style scoped>
.search-results {
  margin-top: 20px;
  padding: 0 20px;
}

.search-input {
  width: 100%;
  height: 60px;
  font-size: 28px;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, .25);
  border-radius: 12px;
  color: #fff;
}

.search-input::placeholder {
  color: #333;
}

.txt-results {
  font-weight: bold;
}


@media(max-width: 991px) {
  

  .keyboard-wrapper {
    display: none !important;
  }

  .search-input {
    
    font-size: 18px;
    height: 50px;
    margin-top: 20px;
  }

}
</style>
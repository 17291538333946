<template>
  <div class="container-fluid my-10" style="min-height: 85vh;">


    <div class="row justify-content-center p-3">


      <h1 class="text-center mb-10">Planos</h1>

      <div class="col-lg-3 col-sm-12" v-for="plan in plans" :key="plan">
        <div class="pricingtable bg-dark">
          <div class="pricingtable-header">
            <h5 class="title">{{ plan.title }}</h5>
          </div>
          <div class="price-value"><span class="currency">R$</span><span class="amount">{{
            plan.price }}</span><br><span class="duration">{{ plan.duration }}</span></div>
          <ul class="pricing-content">
            <li v-for="contents in plan.list " :key="contents">{{ contents.content }}</li>
          </ul>
          <div class="pricingtable-signup"><a class="btn btn-primary btn-lg" target="_blank"
              @click="orderPlan(plan.name)">Obter</a>
          </div>
        </div>
      </div>

      <p class="text-center mt-10 f-12">* É liberado para cada usuário somente 1 Karaokê tocando por vez. Para mais
        Karaokês tocando em simultâneo é necessário obter 1
        plano para cada ou um plano com mais Karaokês.</p>




    </div>

  </div>




  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import Footer from '../components/footer.vue';
import { getUser } from '../data/users';
import { insertOrder } from '../data/orders';
import { app, auth, provider } from '../firebaseConfig'

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'bottom' });


export default {

  components: {
    Footer,
  },
  data() {
    return {
      plans: [
        {
          name: 'diario',
          title: "Plano Diário",
          price: 50,
          duration: '/ dia',
          list: [{ content: "24 horas de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "1 Karaokê por vez *" },
          { content: "Ideal para eventos" }],
        },
        {
          name: 'mensal',
          title: "Plano Mensal",
          price: 250,
          duration: '/ mês',
          list: [{ content: "30 dias de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "1 Karaokê por vez *" },
          { content: "Ideal para bares" }],
        },
        {
          name: 'mensal-pro',
          title: "Plano Mensal - PRO",
          price: 500,
          duration: '/ mês',
          list: [{ content: "30 dias de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "Até 5 Karaokês simultâneos" },
          { content: "Ideal para franquias" }],
        }
      ],
      loaded: false,
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {

    auth.onAuthStateChanged(async user => {

      console.log('user', await getUser())

    });



  },
  computed: {

  },
  methods: {

    async orderPlan(plan) {



      if (auth.currentUser && auth.currentUser.uid && (!auth.currentUser.email || (auth.currentUser.email && auth.currentUser.emailVerified))) {

        var payRedirect = await insertOrder(plan, 'mercadopago');
        if (payRedirect) {
          window.location.href = payRedirect;
        }

      }
      else {
        this.$router.push(
          { name: 'login' }
        )
      }

    }


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style></style>
<template>
  <div class="header-wrapper row m-0">
    <!-- <Search /> -->
    <Logo />

    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0" v-if="this.$route.path !== '/player'">
      <!-- <div class="notification-slider"> -->
      <swiper :slidesPerView="1" :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }" :modules="modules" class="notification-slider" direction="vertical">
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/fire-a.gif" alt="gif" style="height: 22px; aspect-ratio: 150 / 207;">
            <span class="m-l-10 mb-0 f-w-400">
              <router-link class="ms-1" to="/pesquisa">Pesquise e encontre</router-link> <span class="f-light">mais de
                17
                mil músicas</span>
            </span> <i class="icon-arrow-top-right f-light"></i>
          </div>
        </swiper-slide>
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/fire-a.gif" alt="gif" style="height: 22px; aspect-ratio: 150 / 207;">
            <span class="m-l-10 mb-0 f-w-400">
              <router-link class="ms-1" to="/karaoke">Cante agora!</router-link> <span class="f-light">Acesse nosso modo
                Karaokê</span>
            </span> <i class="icon-arrow-top-right f-light"></i>
          </div>
        </swiper-slide>
        <!-- <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/fire-a.gif" alt="gif" style="height: 22px; aspect-ratio: 150 / 207;">
            <span class="m-l-10 mb-0 f-w-400">
              <span class="f-light">Confira nossos planos! </span>
            </span><router-link class="ms-1" to="/planos">Veja!</router-link>
          </div>
        </swiper-slide> -->
      </swiper>

      <!-- </div> -->
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-3 col-3 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li v-if="this.$route.path === '/player' || this.$route.path === '/karaoke'">
          <span style="cursor: pointer; align-items: center; display: flex; flex-direction: row;"
            href="javascript:void(0)" @click="openModal()" class="font-light">
            <vue-feather type="search" style="margin: 0 5px;"></vue-feather></span>
        </li>

        <li class="onhover-dropdown">

          <vue-feather type="play" @click="playlist_open()"
            style="align-items: center; display: flex; flex-direction: row;"></vue-feather>

          <div class="playlist-wrapper onhover-show-div p-30" :class="this.playlist ? 'active' : ''"
            style="max-height: 66vh; overflow-y: auto; background-color: rgba(0,0,0,.95); box-shadow: 0 0 14px #00000099;">
            <h6 class="f-18 pt-0 dropdown-title">Minha Playlist</h6>


            <Playlist v-bind:activeclass="playlist" />


          </div>


        </li>


        <!-- <li>
          <Mode />
        </li> -->

        <li>
          <Maximize />
        </li>

        <Profile />
      </ul>
    </div>
  </div>


  <!-- Modal -->
  <div v-if="isSearchOpen" class="search-modal">
    <div class="search-modal-content">
      <!-- Seu componente vai aqui -->
      <div class="close-modal-button"><vue-feather type="x" @click="closeModal()"></vue-feather></div>
      <Search />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Mode from './mode';
import Maximize from './maximize';
import Profile from './profile';
import Logo from './logo.vue';
import Search from '../search';
import Playlist from '../playlist';


export default {
  //   name: 'Search',
  components: {
    Mode,
    Maximize,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
    Search,
    Playlist
  },
  data() {
    return {
      playlist: false,
      isSearchOpen: false,
    };
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {

    playlist_open() {
      this.playlist = !this.playlist;
    },
    openModal() {
      this.isSearchOpen = true;
    },
    closeModal() {
      this.isSearchOpen = false;
    },
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>
<style>
.header-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

.onhover-dropdown:hover .playlist-wrapper.onhover-show-div {
  top: 44px !important;

}

/* Estilos do modal, ajuste conforme necessário */
.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.search-modal-content {
  background: rgba(5, 5, 7, 1);
  padding: 30px;
  border-radius: 10px;
  width: 80vw;
  height: 80vh;
  overflow: scroll;
  position: relative;
}

.search-modal-content .close-modal-button {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 22px;
}

@media (max-width: 1669px) {
  .page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: auto;
  }
}
</style>

<style scoped>
@media(max-width: 991px) {
  .logo-wrapper {
    display: block !important;
  }

  .left-header {
    display: none !important;
  }

  .search-modal-content {
    padding: 5px;
  }
}
</style>
<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0"
    style="align-items: center; display: flex; flex-direction: row;">
    <div class="media profile-media">
      <img class="b-r-10 img-30" src="@/assets/images/user_placeholder.png" alt="" />
      <div class="media-body">
        <span class="">Olá,</span>
        <p class="mb-0 font-roboto">
          {{ user && user.displayName ? user.displayName || user.email : "Visitante" }} <i
            class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click="login()" v-if="!user">
        <vue-feather type="log-in"></vue-feather><span>Entrar</span>
      </li>
      <li v-if="user" @click="() => { this.$router.push({ name: 'myaccount' }) }">
        <vue-feather type="user"></vue-feather><span>Minha Conta </span>
      </li>
      <li @click="logout()" v-if="user">
        <vue-feather type="log-out"></vue-feather><span>Sair</span>
      </li>
    </ul>
  </li>
</template>

<script>

import { isProxy, toRaw } from 'vue';

// import firebase from 'firebase';
// import UserAuth from '../../auth/js/index';
import { auth } from '../../firebaseConfig'
import { mapState } from 'vuex';


export default {
  name: 'Profile',
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    user(newValue, oldValue) {

      if (isProxy(newValue)) { //this If() block is not really necessary
        newValue = toRaw(newValue)
      }

      console.log('watch Profile', newValue, oldValue);
      this.user = newValue

    }
  },
  methods: {
    login: function () {
      this.$router.replace('/login');
    },
    logout: function () {
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     UserAuth.Logout();


      auth.signOut()
        .then(() => {
          // Sign-out successful.
          this.$store.dispatch('setUser', null)

          localStorage.removeItem('user')

          this.$router.replace('/login');

        })
        .catch((error) => {
          // An error happened
          console.log('logout', error);
        });
    }
    // },
  },
};
</script>
<style>
.profile-dropdown.onhover-show-div {
  background-color: rgba(0, 0, 0, 0.95) !important;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 14px !important;
  padding: 30px !important;
  width: 200px !important;
  right: 0 !important;
  left: unset !important;
}

.onhover-dropdown:hover .profile-dropdown.onhover-show-div {
  top: 44px !important;
}
</style>
<template>
  <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100vw; overflow: hidden;"
    :style="{ height: `${windowHeight}px` }">

    <div style="z-index: 2; position: absolute; width: 100vw; height: 100px; 
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) -10%, rgba(0, 0, 0, 0) 100%) !important;">
    </div>

    <div style="z-index: 1; position: absolute; width: 100vw; height: 100%;" v-if="songUrl">
      <KaraokeflixPlayer ref="player" :song="songUrl" @interface="interface" />
    </div>

  </div>
</template>


<script>
import { ref } from 'vue';
import { mapState } from 'vuex';

import axios from 'axios';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Footer from '../components/footer.vue';
import Songcard from '../components/songcard.vue';
import KaraokeflixPlayer from '../components/karaokeflixplayer/karaokeflixplayer.vue'



export default {

  components: {
    Footer,
    Songcard,
    Carousel,
    Slide,
    Navigation,
    KaraokeflixPlayer
  },
  data() {
    return {
      windowHeight: 0,
      songUrl: null,
      selectionTags: [],
      tags: [],
      selectedTag: null,
      songlist: [],
      teste: null,
      loaded: false,
      // song cards carousel settings
      settings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  mounted() {

    this.updateWindowHeight();
    window.addEventListener('resize', this.updateWindowHeight); // Atualiza a altura quando a janela é redimensionada


    /* document.body.classList.remove('light-only');
    document.body.classList.add('dark-only'); */

    document.addEventListener('keydown', this.handleKeyPress);

    console.log('playlist->', this.playlist);
    console.log('currentSong->', this.currentSong);

    if ((this.currentSong && !this.currentSong[1]) && this.playlist.length > 0) {

      console.log("mounted 1");
      this.$store.dispatch('setCurrentSong', { song: this.playlist[0] });
    }

    this.loadSong();

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowHeight); // Limpa o evento de resize ao destruir o componente
    document.removeEventListener('keydown', this.handleKeyPress);
  },
  computed: {
    /* songlist: function () {
      var songlist = this.$store.state.songlist;
      this.tags = this.loadTags(songlist.songs || []);
      this.selectionTags = this.loadTags(songlist.selections || []);
      return songlist;
    }, */
    ...mapState({
      playlist: (state) => state.playlist,
      currentSong: (state) => state.currentSong,
    }),
  },
  watch: {
    currentSong(newValue, oldValue) {
      console.log('watch currentSong', newValue, oldValue);
      // Executa quando o valor da propriedade 'count' na store muda
      if (newValue !== oldValue) {
        if (this.$refs.player) this.$refs.player.destroyPlayer()
        this.currentSong = newValue
        this.loadSong()
      }
    }
  },
  methods: {
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
      if (this.$refs.player) {
        this.$refs.player.updateDimensions()
      }
    },
    handleKeyPress(event) {
      const pressedKey = event.key.toString().toUpperCase();
      console.log('pressedKey PLAYER:', pressedKey, event.shiftKey);


      if (pressedKey == "ESCAPE") {
        this.$refs.player.escapeHome();
      }
      else {
        if (event.shiftKey) {
          switch (pressedKey) {
            case "ARROWRIGHT": this.nextSong(); break;
            case "ARROWLEFT": this.previousSong(); break;
          }
        }
        else {
          switch (pressedKey) {

            //PLAYER
            case "ENTER": this.$refs.player.playSong(); break;
            case "Q": this.$refs.player.stopSong(); break;
            case "R": this.$refs.player.togglePlay(); break;
            case "W": this.$refs.player.restartSong(); break;
            case "M": this.$refs.player.volumeUp(); break;
            case "N": this.$refs.player.volumeDown(); break;
            case "B": this.$refs.player.updateCdgBackground(); break;

            case "A": this.$refs.player.pitchUp(); break;
            case "V": this.$refs.player.pitchDown(); break;

            case "K": this.$refs.player.rateUp(); break;
            case "L": this.$refs.player.rateDown(); break;kkkkkkkk
          }
        }

      }
    },
    interface(e) {
      //console.log('interface', e);
      switch (e) {
        case 'nextSong': this.nextSong(); break;
        case 'previousSong': this.previousSong(); break;
      }
    },

    async loadSong() {

      this.updateWindowHeight();


      this.$store.dispatch('setLoading', true)


      if (this.currentSong && this.currentSong[1]) {

        var response = await this.requestSongUrl();

        if (response.song && response.song.includes('.karaokeflix')) {
          this.songUrl = response.song
        }
        else if (response.mode == 'cooldown') {
          this.$store.dispatch('setLoading', false)
          toaster.error(`Oops! Você atingiu o limite de músicas carregadas em 1 hora! Por favor tente novamente mais tarde.`)
          this.$router.push({ name: 'home' })
        }
        else {

          this.$store.dispatch('setLoading', false)

          this.$swal({
            icon: "error",
            title: "Oops!",
            text: "Música não localizada, por favor entre em contato com a nossa equipe de suporte!",
            type: "error",
          });
          this.$router.push({ name: 'home' })
        }


      } else {

        this.$store.dispatch('setLoading', false)

        this.$swal({
          icon: "warning",
          title: "Oops!",
          text: "Nenhuma música para tocar!",
          type: "warning",
        });

        this.$router.push({ name: 'home' })


      }
    },

    getCurrentSongIndex() {
      return this.playlist.findIndex(obj => obj[1] === this.currentSong[1])
    },

    nextSong() {
      var currentIndex = this.getCurrentSongIndex();
      console.log('nextSong', currentIndex, this.playlist.length);
      if (currentIndex < this.playlist.length - 1) {
        this.$store.dispatch('setCurrentSong', { song: this.playlist[currentIndex + 1] });
        //PLAYNOW
      }
      else {
        this.$swal({
          icon: "info",
          title: null,
          text: "Sem mais músicas na sua playlist.",
          type: "info",
        });
        this.$store.dispatch('setCurrentSong', { song: [] });


      }
    },
    previousSong() {
      var currentIndex = this.getCurrentSongIndex();
      console.log('previousSong', currentIndex, this.playlist.length);
      if (currentIndex > 0) {
        this.$store.dispatch('setCurrentSong', { song: this.playlist[currentIndex - 1] });
      }
      else {
        this.$swal({
          icon: "info",
          title: null,
          text: "Sem música anterior na sua playlist.",
          type: "info",
        });
      }
    },
    addCredit() {
      console.log('addCredit');

    },
    topScore() {
      console.log('topScore');
    },

    async requestSongUrl() {
      console.log('this.currentSong[1]', this.currentSong[1]);
      var data = null
      var params = {
        code: this.currentSong[1]
      }
      var token = localStorage.getItem("user_jwt")
      var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
      //var config = { headers: { 'Content-type': 'multipart/form-data' } }
      await axios
        .post(
          "https://karaokeflix.nodespace.io/api/karaokeflix/get.song.php",
          params,
          config
        )
        .then((response) => {
          console.log('response', response);
          if (response.data) {
            data = response.data
          }
        })
        .catch((error) => {
          console.log("error", error)
        });
      return data
    }



  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style scoped>
.banners-carousel .carousel {
  border-radius: 8px !important;
  overflow: hidden !important;

}

.banners-carousel .carousel__item {
  min-height: 80vh;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-size: cover;
  background-position: 50% 50%;
  flex-direction: column;
  padding: 100px;
}



.banners-carousel .carousel__item h2 {
  font-size: 100px;
  font-weight: 900;
  color: #fff !important;
  text-shadow: 3px 3px 1px #000;
}

.banners-carousel .carousel__item h4 {
  font-size: 30px;
  font-weight: 500;
  color: #fff !important;
  text-shadow: 2px 2px 1px #000;
}


@media (max-width: 768px) {

  .banners-carousel .carousel__item {
    padding: 50px;
  }

  .banners-carousel .carousel__item h2 {
    font-size: 50px;
  }

  .banners-carousel .carousel__item h4 {
    font-size: 18px;
  }

}


.carousel__slide {
  padding: 0;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: none;
  margin: 0 10px;
  background-color: rgba(0, 0, 0, .5);
  color: #eee;
  border-radius: 8px;
  padding: 10px;
}

.carousel__prev:hover,
.carousel__next:hover {
  color: #fff;
}

.carousel__next--disabled,
.carousel__prev--disabled {
  opacity: 0 !important;
}

.songs-cards-carousel .carousel__track {
  padding-left: 40px !important;
}

.page-wrapper.advance-layout .page-header {
  background-color: none !important;
}

.bt-banner-add {
  background-color: rgb(235, 235, 235) !important;
  border-color: rgb(235, 235, 235) !important;
  color: #333 !important;
  font-weight: 600 !important;
}

.bt-banner-play {
  background-color: rgba(50, 150, 100, 1) !important;
  border-color: rgba(50, 150, 100, 1) !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.bt-banner-add:hover {
  background-color: rgb(225, 225, 255) !important;
}

.bt-banner-play:hover {
  background-color: rgba(40, 140, 90, 1) !important;
}


.badges-tags {

  background-color: #ddd !important;
  color: #333 !important;
}

.badges-tags.tag-selected {
  background-color: #333 !important;
  color: #ddd !important;
}





/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
</style>